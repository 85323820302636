import request from "./request";

//新增供应商
export function supplierAdd(data) {
    return request({
        url: '/project/supplier',
        method: 'post',
        data
    })
}

//修改供应商
export function supplierPut(data) {
    return request({
        url: '/project/supplier',
        method: 'put',
        data
    })
}

//获取供应商
export function getSupplier(id) {
    return request({
        url: '/project/supplier/' + id,
        method: 'get'
    })
}

//删除供应商
export function supplierDeltel(ids) {
    return request({
        url: '/project/supplier/' + ids,
        method: 'delete'
    })
}

//导出供应商
export function supplierExport(data) {
    return request({
        url: '/project/supplier/export',
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

//导入供应商
export function supplierImportData(data) {
    return request({
        url: '/project/supplier/importUpdateType',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

//获取供应商列表
export function supplierList(query) {
    return request({
        url: '/project/supplier/list',
        params: query
    })
}
