<template>
    <div class="bkColor">
        <headTitle :title="title"></headTitle>
        <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
            @cancel="onCancel" style="margin-bottom: 2vh" />
        <!-- <div class="search">
            <div>状态:</div>
            <div style="
            display: flex;
            justify-content: space-around;
            align-items: center;
          ">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.state" :options="stateOption" @change="changePjState" />
                </van-dropdown-menu>
                <div class="searchTitle">项目年份:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.year" :options="yearOption" @change="changeYear" />
                </van-dropdown-menu>
                <div class="searchTitle">支付:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.isSettle" :options="isSettleOption" @change="changeIsSettle" />
                </van-dropdown-menu>
                <div class="searchTitle">归属:</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
                </van-dropdown-menu>
            </div>
        </div> -->
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getSupplierList">
                    <div class="listCell" v-for="item in list" :key="item.id" @mouseup="up($event)"
                        @mousedown="down($event)" @click="goDetails(item.id)">
                        <van-swipe-cell :disabled="powerList == ''">
                            <div class="cellName">{{ item.name }}</div>
                            <div class="cellText">联系人：{{ item.contacts }}</div>
                            <div class="cellText">联系电话：{{ item.mobile }}</div>
                            <div class="cellText">地址：{{ item.address }}</div>
                            <div class="cellText">主营业务：{{ item.mainProducts }}</div>
                            <template #right>
                                <van-button type="danger" native-type="button"
                                    @click="deleteSupplier(item.id)">删除供应商</van-button>
                            </template>
                        </van-swipe-cell>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="addBtn">
            <img src="../assets/icon/xz_icon.png" alt="" @click="isModify = true" />
            <van-popup v-model="isModify" :style="{ width: '80%', padding: '20px', maxHeight: '80%', overflow: 'auto' }"
                round>

                <van-form validate-first @failed="onFailed" @submit="submitForm" ref='form'>

                    <van-field v-model="addFrom.name" label="供应商单位" placeholder="请输入"
                        :rules="[{ required: true, message: '供应商单位不能为空' }]" required />
                    <van-field v-model="addFrom.address" label="单位地址" type="textarea" autosize placeholder="请输入"
                        :rules="[{ required: true, message: '单位地址不能为空' }]" required />
                    <van-field v-model="addFrom.contacts" label="联系人" placeholder="请输入" />
                    <van-field v-model="addFrom.mobile" label="联系电话" placeholder="请输入"
                        :rules="[{ required: true, message: '联系电话不能为空' }]" required />
                    <van-field v-model="addFrom.bank" label="开户行" type="textarea" autosize placeholder="请输入"
                        :rules="[{ required: true, message: '开户行不能为空' }]" required />
                    <van-field v-model="addFrom.bankNumber" label="行账号" placeholder="请输入"
                        :rules="[{ required: true, message: '行账号不能为空' }]" required />
                    <van-field v-model="addFrom.taxNumber" label="税号" placeholder="请输入"
                        :rules="[{ required: true, message: '税号不能为空' }]" required />
                    <van-field v-model="addFrom.mainProducts" type="textarea" autosize label="主营产品" placeholder="请输入" />
                    <van-field name="radio" label="有无营业执照" required>
                        <template #input>
                            <van-radio-group v-model="addFrom.businessLicense" direction="horizontal" @change="clearFile">
                                <van-radio :name="1">有</van-radio>
                                <van-radio :name="0">无</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-field v-show="addFrom.businessLicense == '1'" name="uploader" label="附件">
                        <template #input>
                            <van-uploader v-model="uploader" @click-preview="viewFile" accept="file" upload-icon="plus"
                                :after-read="afterRead" :before-read="beforeRead" @delete="deleteFile"
                                :preview-full-image="false" />
                        </template>
                    </van-field>

                    <van-field name="radio" label="有无报价单" required>
                        <template #input>
                            <van-radio-group v-model="addFrom.priceList" direction="horizontal">
                                <van-radio :name="1">有</van-radio>
                                <van-radio :name="0">无</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <van-button round block type="info" native-type="submit" size="small" :disabled="isLoading"
                        :loading="isLoading" loading-text="加载中...">提交</van-button>
                </van-form>
            </van-popup>

        </div>
        <div v-show="isReviewImg"
            style="position: fixed;top: 0;left:0;right:0;bottom:0;margin:auto;width: 100%;height: 100%;background: #323233;z-index: 9999;overflow: auto;"
            @click="isReviewImg = false">
            <img :src="reViewImg" alt="" style="width: 100%;position: absolute;top: 0;left: 0;bottom: 0;right: 0;" />
        </div>
        <!-- <div class="exportBtn" @click="exportFile" v-show="powerList.includes('2') || powerList.includes('1')">导出</div>
        <div class="importBtn" @click="importFile" v-show="powerList.includes('2') || powerList.includes('1')">导入</div> -->
    </div>
</template>
    
<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
    Search,
    Toast,
    List,
    PullRefresh,
    Cell,
    SwipeCell,
    Uploader,
    Button,
    Dialog,
    Radio,
    RadioGroup,
    Form,
    Popup,
    Field
} from "vant";

import { projectStatusText, settlementText } from "../units/units";
import { supplierList, supplierDeltel, supplierAdd } from "../api/supplier";
import { fileUpload } from '../api/api'
import { getToken } from "../units/auth";

Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(SwipeCell);
Vue.use(Uploader);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Form)
Vue.use(Popup)
Vue.use(Field)
export default {
    name: "supplierList",
    components: {
        headTitle,
    },
    data() {
        return {
            title: "供应商列表",
            // stateOption: [
            //     { text: "全部", value: null },
            //     { text: "审核中", value: 0 },
            //     { text: "审核通过", value: 1 },
            //     { text: "审核不通过", value: 2 },
            // ],
            // yearOption: [
            //     { text: "全部", value: null },
            //     { text: "2020", value: 2020 },
            //     { text: "2021", value: 2021 },
            //     { text: "2022", value: 2022 },
            //     { text: "2023", value: 2023 },
            //     { text: "2024", value: 2024 },
            //     { text: "2025", value: 2025 },
            //     { text: "2026", value: 2026 },
            // ],
            // isSettleOption: [
            //     { text: "全部", value: null },
            //     { text: "未支付", value: 0 },
            //     { text: "已支付", value: 1 },
            //     { text: "拒绝支付", value: 2 },
            // ],
            // belongOption: [
            //     { text: "全部", value: null },
            //     { text: "大美", value: "1" },
            //     { text: "中创", value: "2" },
            // ],
            value: "",
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            pageX: 0,
            pageY: 0,
            isDrag: false,
            queryFrom: {
                vague: "",
                pageSize: 10,
                pageNum: 1,
            },
            addFrom: {
                name: '',
                address: '',
                contacts: '',
                mobile: '',
                bank: '',
                bankNumber: '',
                mainProducts: '',
                businessLicense: 0,
                priceList: 0,
                taxNumber: '',
                fileList: [], //文件列表

            },
            powerList: [], //value 1总经理 2财务 3行政
            userId: "",
            isModify: false,
            isLoading: false,
            equipment: false,

            uploader: [], //附件
            uploading: false, //是否正在上传
            isReviewImg: false,//是否预览图片
            reViewImg: '',
        };
    },
    mounted() {
        this.getUserId();
        this.getPowerList();
        // this.gett()
        this.isMobile()
    },
    methods: {
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken("powerList") || "[]");
        },
        getUserId() {
            this.userId = getToken("userId");
        },
        getSupplierList() {
            this.loading = true;
            supplierList(this.queryFrom).then((res) => {
                this.list =
                    this.queryFrom.pageNum == 1
                        ? res.data.rows
                        : this.list.concat(res.data.rows);
                this.refreshing = false;
                this.loading = false;
                this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
                if (
                    res.data.total <=
                    (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize
                ) {
                    this.finished = true;
                }
            });
        },
        //财务是否结算
        settlement(f) {
            return settlementText(f);
        },
        stateText(t) {
            return projectStatusText(t);
        },
        changePjState(val) {
            this.queryFrom.state = val;
            this.onRefresh();
        },
        changeYear(val) {
            this.queryFrom.year = val;
            this.onRefresh();
        },
        changeIsSettle(val) {
            this.queryFrom.isSettleOption = val;
            this.onRefresh();
        },
        changeBelong(val) {
            this.queryFrom.belong = val;
            this.onRefresh();
        },
        onSearch(val) {
            console.log(val);
            this.queryFrom.vague = val;
            this.onRefresh();
        },
        //搜索框取消
        onCancel() {
            this.queryFrom.vague = "";
            this.queryFrom.state = null;
            this.queryFrom.isSettle = null;
            this.queryFrom.belong = null;
            this.onRefresh();
        },
        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1;
            this.list = [];
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getSupplierList();
        },
        add() {
            this.$router.push({
                path: "/newPjPayment",
            });
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        submitForm() {
            this.isLoading = true
            supplierAdd(this.addFrom).then(res => {
                if (res.data.code == 200) {
                    this.isLoading = false
                    Toast.success("提交成功");
                    this.isModify = false
                    this.onRefresh();
                    this.addFrom = {
                        name: '',
                        address: '',
                        contacts: '',
                        mobile: '',
                        bank: '',
                        bankNumber: '',
                        mainProducts: '',
                        businessLicense: 0,
                        priceList: 0,
                        taxNumber: '',
                        fileList: []
                    }
                    this.uploader = []
                }
            }).catch(() => {
                this.isLoading = false
            })
        },
        //供应商删除
        deleteSupplier(id) {
            Dialog.confirm({
                message: `是否删除该供用商`,
                confirmButtonColor: "#0092fe",
            }).then(() => {
                supplierDeltel(id).then((res) => {
                    if (res.data.code == 200) {
                        Toast.success("完成操作");
                        this.onRefresh();
                    }
                });
            });
        },
        goDetails(id) {
            if (this.isDrag) {
                return;
            }
            this.$router.push({
                path: "/supplierDetails",
                query: { id: id },
            });
        },
        up(event) {
            if (this.pageX !== event.pageX) {
                this.isDrag = true;
            } else {
                this.isDrag = false;
            }
        },
        down(event) {
            this.pageX = event.pageX;
            this.pageY = event.pageY;
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        },
        beforeRead(file) {
            if (file.type == 'application/msword') {
                Toast('请将.doc类型文件另存为改成.docx类型的文件后再进行上传（请注意:手动改后缀可能文件类型并没有变化）')
                return
            }
            //上传之前校验
            if (file.size > 30 * 1024 * 1024) {
                Toast("只允许上传30M的文件");
                return false;
            }
            return true;
        },
        async afterRead(file) {
            //文件读取完成后的回调函数
            file.status = "uploading";
            file.message = "上传中...";
            this.uploading = true;
            let fd = new FormData();
            fd.append("file", file.file);
            await fileUpload(fd)
                .then((res) => {
                    if (res.data.code == 200) {
                        let obj = {
                            fileName: res.data.originalName,
                            url: res.data.url,
                        };
                        this.uploader[this.uploader.length - 1].url = res.data.url; //给返回的文件添加url属性
                        this.uploader[this.uploader.length - 1].fileName =
                            res.data.originalName;
                        this.addFrom.fileList.push(obj);
                        file.status = "";
                        file.message = ""; //上传状态
                        this.uploading = false;
                    } else {
                        file.status = "failed";
                        file.message = "上传失败";
                        this.uploading = false;
                    }
                }).catch((err) => {
                    if (err) {
                        file.status = "failed";
                        file.message = "上传失败";
                        this.uploading = false;
                    }
                });
        },
        deleteFile(file) {
            this.queryFrom.fileList = this.queryFrom.fileList.filter((it) => {
                return it.url !== file.url;
            });
        },
        viewFile(file) {
            if (
                file.url.indexOf(".jpg") !== -1 ||
                file.url.indexOf(".jpeg") !== -1 ||
                file.url.indexOf(".png") !== -1
            ) {
                this.isReviewImg = true
                this.reViewImg = file.url
                return;
            }
            this.$router.push({
                path: "/preview",
                query: { url: file.url, routerPath: "/supplierList" },
            });
        },
        clearFile() {
            this.queryFrom.fileList = []
            this.uploader = []
        }
    },
};
</script>
    
<style lang="less" scoped>
.blue {
    color: #1989fa;
}

.red {
    color: #e86f61;
}

.green {
    color: #00ab45;
}

.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
    position: relative;
}

.search {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    background: #fff;
    font-size: 13px;
    line-height: 8vh;
}

.searchTitle {
    margin-left: 2vw;
}

/deep/ .van-dropdown-menu__title {
    padding: 0 8px 0 0;
}

/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #f6f6f6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: right;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

.addBtn {
    width: 55px;
    height: 55px;
    position: fixed;
    top: 77%;
    left: 77%;
    cursor: pointer;

    img {
        width: 55px;
        height: 55px;
    }
}

.exportBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 55%;
    left: 77.3%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.importBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 66%;
    left: 77.3%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer
}

// /deep/ .van-button {
//     height: 100%;
// }
.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>